// Generated by Framer (8c7926c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const ImageWithFX = withFX(Image);

const cycleOrder = ["d8UIIYUau", "E10J5_mL0", "fd_NwkJTu"];

const serializationHash = "framer-mOqNk"

const variantClassNames = {d8UIIYUau: "framer-v-10qgsjw", E10J5_mL0: "framer-v-15lxqtx", fd_NwkJTu: "framer-v-1mtge2i"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 1, ease: [0, 0, 1, 1], type: "tween"}

const animation = {opacity: 1, rotate: 8, rotateX: 4, rotateY: 8, scale: 1, skewX: 0, skewY: 0, x: 0, y: 0}

const transition2 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "d8UIIYUau", "Variant 2": "E10J5_mL0", "Variant 3": "fd_NwkJTu"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "d8UIIYUau"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "d8UIIYUau", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition2}><ImageWithFX {...restProps} {...gestureHandlers} __framer__loop={animation} __framer__loopEffectEnabled __framer__loopRepeatDelay={0} __framer__loopRepeatType={"mirror"} __framer__loopTransition={transition1} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} background={{alt: "", fit: "fill", intrinsicHeight: 168, intrinsicWidth: 168, loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 336, pixelWidth: 336, src: "https://framerusercontent.com/images/p9GARs0HuE4sRKXXPv5dkfdTa4.png"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-10qgsjw", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"d8UIIYUau"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 307, borderBottomRightRadius: 307, borderTopLeftRadius: 307, borderTopRightRadius: 307, boxShadow: "0.47422285992186514px 0.47422285992186514px 0.6706524000888582px -0.75px rgba(0, 0, 0, 0.1838), 1.2914347811369224px 1.2914347811369224px 1.8263645824041657px -1.5px rgba(0, 0, 0, 0.17741), 2.8355233674636113px 2.8355233674636113px 4.010035602692868px -2.25px rgba(0, 0, 0, 0.16535), 6.294214453822934px 6.294214453822934px 8.901363445081158px -3px rgba(0, 0, 0, 0.13833), 16px 16px 22.627416997969522px -3.75px rgba(0, 0, 0, 0.0625)", ...style}} variants={{fd_NwkJTu: {borderBottomLeftRadius: 223, borderBottomRightRadius: 223, borderTopLeftRadius: 223, borderTopRightRadius: 223}}} {...addPropertyOverrides({E10J5_mL0: {"data-framer-name": "Variant 2"}, fd_NwkJTu: {"data-framer-name": "Variant 3"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mOqNk.framer-18i7uu1, .framer-mOqNk .framer-18i7uu1 { display: block; }", ".framer-mOqNk.framer-10qgsjw { height: 80px; overflow: visible; position: relative; width: 80px; }", ".framer-mOqNk.framer-v-15lxqtx.framer-10qgsjw { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 300px); width: 300px; }", ".framer-mOqNk.framer-v-1mtge2i.framer-10qgsjw { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 80px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 80
 * @framerIntrinsicWidth 80
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"E10J5_mL0":{"layout":["fixed","fixed"]},"fd_NwkJTu":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramertWc_NM3oX: React.ComponentType<Props> = withCSS(Component, css, "framer-mOqNk") as typeof Component;
export default FramertWc_NM3oX;

FramertWc_NM3oX.displayName = "W logo movement";

FramertWc_NM3oX.defaultProps = {height: 80, width: 80};

addPropertyControls(FramertWc_NM3oX, {variant: {options: ["d8UIIYUau", "E10J5_mL0", "fd_NwkJTu"], optionTitles: ["Variant 1", "Variant 2", "Variant 3"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramertWc_NM3oX, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})